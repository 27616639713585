import {EBlockPosition, EBlockType} from '@redux/types';
import {MouseEvent} from 'react';
import {scrollToElementById} from '@common/utils/scrollToElementById';


export const getRichTextElementClasses = (type: EBlockType, position: EBlockPosition) => {
    const classesByType = {
        [EBlockType?.grid]: 'inner-simple-text mt-65 mt-md-40 mt-sm-40',
        [EBlockType?.simple]: 'inner-simple-text inner-simple-text--margin-bottom-small inner-simple-text--margin-top-big',
        [EBlockType?.sticky]: 'inner-simple-text mt-25 mt-md-20 mt-sm-5',
        [EBlockType?.table]: 'inner-simple-text',
        [EBlockType?.tools]: 'inner-tools__text',
    }[type];

    const classesForOutsideStep =  `inner-simple-text step-description inner-simple-text--mobile-margin${
        type !== EBlockType.sticky ? '' : ' mt-25 mt-md-20 mt-sm-5'}`;

    return position === EBlockPosition.outsideStep ? classesForOutsideStep : classesByType
}


export const isListItemHaveLink = (items: any) => {
    return !!items?.every((c: any) => c.type === 'link' || (c.type !== 'link' && c.text === ''))
}

export const handleAnchorClick = (e: MouseEvent<HTMLAnchorElement>, url: string = '') => {
    const id = url?.includes('#') ? url.split('#').pop() : null
    if (id) {
        e.preventDefault();
        scrollToElementById(id);
    }
}
