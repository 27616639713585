import React, {memo} from 'react';
import {EBlockPosition, EBlockType, IStepComponent, TStepChildPosition, TStepChildType} from '@redux/types';
import {isNotEmptyRichContent} from '@common/functions';
import Text from '@common/components/common/Text';
import {RichTextElementList} from '@components/RichTextContent/RichTextElementList';

interface IRichTextContentProps {
  readonly content: any
  parent?: IStepComponent | null
  title?: string,
  position?: TStepChildPosition | null,
  type?: TStepChildType
  classes?: string
  isBlock?: boolean
}


const RichTextContent = (props: IRichTextContentProps) => {

  const {
    content,
    title,
    parent,
    position,
    type,
    classes,
    isBlock = false
  } = props;

  if (typeof content === 'string') {
    return content
  }

  if (!content || !isNotEmptyRichContent(content)) {
    return (title && (
      <h2 className={`inner-simple-title inner-simple-title--no-margin inner-simple-title--margin-top-40-40-40`}>
        <Text text={title}/>
      </h2>
    ));
  }

  const RichTitleBlock = title && (
    <h2 className={`inner-simple-title inner-simple-title--margin-top-40-40-40`}>
      <Text text={title}/>
    </h2>
  )

  return (
    <>
      {position === EBlockPosition.insideStep || position === EBlockPosition.outsideStep ? (
        <div className={`inner-grid__content ${isBlock && 'inner-grid__block'}`}>
          {RichTitleBlock}
          <RichTextElementList
            content={content}
            position={position}
            type={type || EBlockType.sticky}
            parent={parent || null}
          />
        </div>
      ) : (
        <>
          {RichTitleBlock}
          <RichTextElementList
            content={content}
            position={position || null}
            type={type || EBlockType.sticky}
            parent={parent || null}
            classes={classes || ''}
          />
        </>
      )}
    </>
  );
}

export default memo(RichTextContent);


