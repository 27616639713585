import {EBlockPosition, EBlockType, EComponent, IStepComponent} from '@redux/types';
import Link from 'next/link';
import Text from '@components/common/Text';
import React from 'react';
import {useActions} from '@common/hooks/useActions';
import {getRichTextElementClasses, handleAnchorClick, isListItemHaveLink} from '@components/RichTextContent/functions';

interface IRichAtomicElementProps {
  type?: string,
  format?: string,
  level?: number,
  url?: string,
  text?: string,
  children?: IRichAtomicElementProps[] | undefined
}

interface IRichTextElementProps {
  childrenItems: IRichAtomicElementProps[],
  index?: number,
  isStarting?: boolean,
  format?: string,
  isLinkList?: boolean,
  classes?: string,
  position?: EBlockPosition,
  type?: EBlockType,
  parent?: IStepComponent | null,
}

/**
 * Определение, какой элемент верстки должен быть отображен
 * @param childrenItems - массив элементов верстки (параграфы, текст, списки и т.д.)
 *                        Каждый элемент - JSON-объект с несколькими уровнями вложенности айтемов такого же формата
 * @param index - номер элемента в своем цикле
 * @param isStarting - начальный элемент
 * @param format - формат элемента, используется только для списка
 * @param isLinkList - состоит ли список только из ссылок
 * @param classes - дополнительные стили
 * @param position - позиция родительского элемента
 * @param type - тип родительского элемента
 * @param parent - родительский элемент
 * @constructor
 */
export const RichTextElement = ({childrenItems, index, isStarting,
                        format, isLinkList, classes, position, type, parent}: IRichTextElementProps) => {

  const actions = useActions()

  if (!childrenItems?.length) {
    return null;
  }

  /**
   * Рекурсивные вызовы компонента RichTextElement, с вложенным в текущем элементе списком дочерних элементов
   * @param item - элемент верстки (типы перечислены в коде switch)
   */
  const getContent = (item: any) => {
    const itemType = item?.type as 'paragraph' | 'text' | 'link' | 'heading' | 'list' | 'list-item' | 'quote'

    switch (itemType) {
      case 'paragraph': {
        return (
          <p className={classes || ''}><RichTextElement childrenItems={item?.children}/></p>
        )
      }
      case 'heading': {
        let parentType = index !== 0 || !type ? EBlockType.sticky : type;

        const classesByNumber: {[key: number]: string} = {
          [1]: 'inner-simple-text inner-simple-text--font-40-35-20 mt-65 mt-md-40',
          [2]: 'inner-styled-list__subtitle inner-styled-list__subtitle--font-40-35-20',
          [3]: 'inner-styled-list__subtitle inner-styled-list__subtitle--font-40-35-20',
          [4]: 'inner-simple-text mt-65 mt-md-40',
          [0]: `${getRichTextElementClasses(parentType, position || EBlockPosition.insideStep)} mb-65 mb-md-40`
        }
        let headerClassName: any = classesByNumber[item?.level || 0];

        return (
          <div className={headerClassName}>
            <RichTextElement childrenItems={item?.children}/>
          </div>
        )
      }
      case 'quote': {
        return (
          <div className="inner-simple-box inner-simple-box--bg-white inner-simple-box--margin-top-bottom">
            <div className="inner-simple-text inner-simple-text--mobile-margin-big">
              <RichTextElement childrenItems={item?.children}/>
            </div>
          </div>
        )
      }
      case 'link': {
        const isSelf = item.url.includes('127.0.0.1:3000') || item.url.includes('mailto') || item.url.includes('tel')
        const isParentGrid = parent?.__component === EComponent.GRID;
        const linkClassName = isParentGrid ? "inner-simple-cards__link" : "inner-simple-links__link";

        return (
          <Link
            onClick={(e) => {
              if (!item?.url.includes('#request') || isParentGrid) {
                handleAnchorClick(e, item?.url)
              } else {
                e.preventDefault()
                actions.setApp({requestForm: {open: true}})
              }
            }}
            href={item?.url}
            className={linkClassName}
            target={isSelf ? '_self' : '_blank'}
          >
            <RichTextElement childrenItems={item?.children}/>
          </Link>
        )
      }
      case 'list-item': {
        const isUnorderedListItem = format === 'unordered';
        const listItemClassName = !isUnorderedListItem ? "inner-navigation-block__navigation-item" : (
          isLinkList ? "inner-simple-links__item" : "inner-simple-list__item"
        )
        return (
          <li className={listItemClassName}>
            <p><RichTextElement childrenItems={item?.children}/></p>
          </li>
        )
      }
      case 'list': {
        const isLinkList = item?.children?.every((c: any) => isListItemHaveLink(c?.children));
        const isUnorderedList = item?.format === 'unordered';

        const unorderedListClasses = isLinkList ? "inner-simple-links" :
          !isStarting ? "inner-simple-list inner-simple-list--mobile-margin-big" : "inner-simple-list";

        const orderedListClasses = !isStarting ? "inner-navigation-block__navigation-list" : "inner-navigation-block__navigation-list";

        return (isUnorderedList ? (
            <ul className={unorderedListClasses}>
              <RichTextElement childrenItems={item?.children} format={item?.format} isLinkList={isLinkList}/>
            </ul>
          ) : (
            <ol
              className={orderedListClasses}>
              <RichTextElement childrenItems={item?.children} format={item?.format} isLinkList={isLinkList}/>
            </ol>)
        )
      }
      case 'text': {
        // text - конечных элементов json-объекта
        // у элемента текста могут быть дополнительные характеристики,
        // которые указывают, должен ли быть текст жирным, подчеркнутым и др.
        if (item?.bold) {
          return <strong><RichTextElement childrenItems={[{...item, bold: false, text: item?.text, type: 'text'}]}/></strong>
        }
        if (item?.italic) {
          return <em><RichTextElement childrenItems={[{...item, italic: false, text: item?.text, type: 'text'}]}/></em>
        }
        if (item?.underline) {
          return <u><RichTextElement childrenItems={[{...item, underline: false, text: item?.text, type: 'text'}]}/></u>
        }
        if (item?.strikethrough) {
          return <s><RichTextElement childrenItems={[{...item, strikethrough: false, text: item?.text, type: 'text'}]}/></s>
        }

        return <Text text={item?.text}/>
      }
      default:
        return null
    }
  }

  return (
    childrenItems?.map((item: any, index: number) => {
      return <React.Fragment key={index}>{getContent(item)}</React.Fragment>
    })
  )
}
