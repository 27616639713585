import React, {useMemo} from 'react';
import {EBlockPosition, EBlockType, IStepComponent, TStepChildPosition, TStepChildType} from '@redux/types';
import {getRichTextElementClasses} from '@components/RichTextContent/functions';
import {RichTextElement} from '@components/RichTextContent/RichTextElement';


export const RichTextElementList: React.FC<{
  readonly content: any
  position?: TStepChildPosition | null,
  parent?: IStepComponent | null
  type?: TStepChildType
  classes?: string,
}> = ({content, type, position, parent, classes}) => {

  let initialContent: any[] = useMemo(() => {
    let contentType = '';
    let temp: any[] = [];

    /**
     * Формирование массива вложенных массивов, чтобы разграничить начало и конец однотипных элементов.
     * Необходимо для случая, когда контент располагается внутри или вне шага (не в описании) + первым идет параграф
     * Тогда, элементы должны быть дополнительно обернуты в div
     */
    content?.forEach((c: any) => {
      if (contentType !== c?.type) {
        contentType = c?.type || ''
        temp.push([c])
      } else {
        temp[temp?.length - 1]?.push(c)
      }
    })
    return temp;
  }, [content]);

  return (initialContent?.map((items: any, index) => {
    if (items[0]?.type === 'paragraph' && !!parent && !classes) {
      const blockType = (index !== 0 || !type) ? EBlockType.sticky : type
      return (
        <div key={`block_p_${index}`}
             className={getRichTextElementClasses(blockType, position || EBlockPosition.insideStep)}>
          <RichTextElement
            childrenItems={items}
            isStarting={true}
            classes={classes}
            index={index}
            position={position || EBlockPosition.insideStep}
            type={type}
            parent={parent}
          />
        </div>
      )
    }

    return (
      <RichTextElement
        key={`block_np_${index}`}
        childrenItems={items}
        isStarting={items[0]?.type === 'list' && index === 0}
        classes={classes}
        index={index}
        position={position || EBlockPosition.insideStep}
        type={type}
        parent={parent}
      />
    )
  }))
}
