import {scroller} from 'react-scroll';

export const scrollToElementById = (id: string) => {
  const element = document.getElementById(id)
  const header = document.getElementById('header')
  if (element) {
    scroller.scrollTo(id, {
      duration: 800,
      smooth: 'easeOutQuart',
      offset: -(header?.clientHeight ?? 0)
    })
  }
}
